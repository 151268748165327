(window as any).global = window;

window.Buffer = window.Buffer || require('buffer').Buffer;

(window as any).process = {
  env: { DEBUG: undefined },
  version: [],
  nextTick: function(): null {
    return null;
  }
} as any;
